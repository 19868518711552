/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PButtonComponent } from '../../p-button/p-button.component';

export interface ExtraText {
  text: string;
  isBold?: boolean;
  isGrow?: boolean;
}

@Component({
  selector: 'p-modal-confirm',
  standalone: true,
  imports: [CommonModule, PButtonComponent],
  templateUrl: './p-modal-confirm.component.html',
  styleUrls: ['./p-modal-confirm.component.css'],
})
export class PModalConfirmComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'large';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() extraText: ExtraText[] = [];
  @Input() icon: 'done' | 'error' | 'warning' = 'done'; // You can use an icon library here
  @Input() titleButton = 'Ok';
  @Input() showTitleButton = true;
  @Output() onClose = new EventEmitter<void>();
  @Input() isVisible = false;
  cancel() {
    this.isVisible = false;
    this.onClose.emit();
  }

  get sizeClass(): string {
    return {
      small: 'w-[20rem]',
      medium: 'w-[28rem]',
      large: 'w-[35rem]',
    }[this.size];
  }
}
